import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import {
  EmailShareButton,
  EmailIcon,
  TwitterShareButton,
  XIcon,
  LinkedinShareButton,
  LinkedinIcon,
  FacebookShareButton,
  FacebookIcon,
} from 'react-share';

// Asset Imports
import Stripe03SVG from '../../images/stripes/stripes03.inline.svg';
import Stripe10SVG from '../../images/stripes/stripes10.inline.svg';

// Component Imports
import SEO from '../../components/seo';
import Container from '../../components/container';
import {
  HeroPreHeader,
  PreHeader,
  SuperHeader,
  P1,
  ButtonText,
  QuoteLarge,
  TechnicalSmall,
  D2,
} from '../../theme/typography';
import Spacer from '../../components/spacer';
import BottomCut from '../../components/bottomCut';
import { Section } from '../../components/page-elements';
import CTABlock from '../../components/ctaBlock';
import BreathingPlayButton from '../../components/breathingPlayButton';
import AnimatedStripe from '../../components/animatedStripe';
import LogoMarquee from '../../components/logoMarquee';
import VideoModal from '../../components/videoModal';

//Function Imports

//Styled Components
const ArticleContainer = styled.div`
  margin: 0 auto;
  max-width: 680px;
  color: var(--blue1);
`;

const Subtitle = styled(HeroPreHeader)`
  max-width: 620px;
`;

const Divider = styled.hr`
  width: 100%;
  border: none;
  border-top: 1px solid var(--blue5);
`;

const InfoRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Date = styled(ButtonText)`
  color: var(--blue4);
  sup {
    text-transform: none;
  }
`;

const ShareIcons = styled.div`
  display: flex;
  gap: 4px;

  > button {
    height: 32px;
  }

  svg {
    border-radius: 50%;
    transition: all 0.2s ease-in-out;

    &:hover {
      opacity: 0.8;
      transform: scale(0.95);
    }
  }
`;

const PosterImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;

  @media (hover: hover) {
    transition: transform 0.4s ease;
  }

  > img {
    border-radius: var(--border-radius);
  }
`;

const VideoThumbWrapper = styled.div`
  --border-radius: 8px;

  position: relative;
  border-radius: var(--border-radius);
  overflow: hidden;
  cursor: pointer;
  width: 100%;
  aspect-ratio: 16 / 9;

  @media (hover: hover) {
    &:hover {
      ${PosterImage} {
        transform: scale(1.05);
      }
    }
  }
`;

const PlayButtonWrap = styled.div`
  position: absolute;
  right: 48px;
  bottom: 48px;
`;

const ModalWrapper = styled.div`
  /* Take it out of document flow */
  position: absolute;
  /* Just to overwrite the modal video plugins styles */
  .modal-video-movie-wrap {
    background: none;
  }

  .modal-video {
    background-color: rgba(0, 0, 0, 0.9);
  }

  .modal-video-close-btn {
    cursor: pointer;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;

  > * {
    border-radius: 4px;

    @media (min-width: 1000px) {
      transform: translateX(-80px);
    }
  }
`;

const ImageStripes01 = styled(AnimatedStripe)`
  position: absolute;
  width: 80%;
  height: 80%;
  transform: scaleX(-1);
  left: -50%;
  bottom: 15%;

  @media (min-width: 500px) {
    left: -50%;
    bottom: 0;
  }
  @media (min-width: 1000px) {
    left: -40%;
  }
`;

const ImageStripes02 = styled(AnimatedStripe)`
  position: absolute;
  width: 30%;
  height: 30%;
  top: -6%;
  right: -8%;
`;

const ImageCaption = styled(TechnicalSmall)`
  text-align: right;
  max-width: 400px;
`;

const Blockquote = styled(D2)`
  position: relative;
  padding-left: 32px;
  :before {
    content: '';
    background-color: var(--blue4);
    height: 90%;
    width: 2px;
    position: absolute;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 4px;
  }
`;

function removeDuplicateCompanies(arrayOfObjects) {
  const seenCompanies = {};

  return arrayOfObjects.filter((obj) => {
    if (!seenCompanies[obj.company]) {
      seenCompanies[obj.company] = true;
      return true;
    }
    return false;
  });
}

const ItsNotRocketSciencePage = ({ data }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const shareUrl = 'https://www.rubberduck.ie/news/its-not-rocket-science';
  const shareTitle = `It's Not Rocket Science`;

  const { inrsVideo, groupPhoto, ctaBlock } = data.sanityInrsPage;

  //Removes extra nesting level from query
  const clients = data.allSanityVideo.nodes.map(({ client }) => ({
    company: client.company,
    svgLogo: client.svgLogo,
  }));

  const uniqueClients = removeDuplicateCompanies(clients);

  return (
    <>
      <SEO
        title={`It's Not Rocket Science`}
        description={`Production companies Rocket Science and Rubberduck merge`}
      />
      <Helmet>
        <body className='light-nav' />
      </Helmet>
      <Section light cuts={'bottomCut'} style={{ overflow: 'clip' }}>
        <Container>
          <ArticleContainer>
            <PreHeader as={'h3'} style={{ color: 'var(--accent)' }}>
              Newsroom
            </PreHeader>
            <Spacer size={4} />
            <SuperHeader style={{ lineHeight: `1.2` }}>
              It's Not Rocket Science
            </SuperHeader>
            <Spacer size={24} />
            <Subtitle as={'h1'}>
              Production companies Rocket Science and Rubberduck merge
            </Subtitle>
            <Spacer size={32} />
            <Divider />
            <Spacer size={8} />
            <InfoRow>
              <Date>
                13<sup>th</sup> Feb 2024
              </Date>
              <ShareIcons>
                <LinkedinShareButton url={shareUrl}>
                  <LinkedinIcon
                    size={32}
                    round
                    bgStyle={{
                      fill: 'var(--blue5)',
                    }}
                    iconFillColor={'var(--blue3)'}
                  />
                </LinkedinShareButton>
                <FacebookShareButton url={shareUrl}>
                  <FacebookIcon
                    size={32}
                    round
                    bgStyle={{
                      fill: 'var(--blue5)',
                    }}
                    iconFillColor={'var(--blue3)'}
                  />
                </FacebookShareButton>
                <TwitterShareButton url={shareUrl} title={shareTitle}>
                  <XIcon
                    size={32}
                    round
                    bgStyle={{
                      fill: 'var(--blue5)',
                    }}
                    iconFillColor={'var(--blue3)'}
                  />
                </TwitterShareButton>
                <EmailShareButton url={shareUrl} subject={shareTitle}>
                  <EmailIcon
                    size={32}
                    round
                    bgStyle={{
                      fill: 'var(--blue5)',
                    }}
                    iconFillColor={'var(--blue3)'}
                  />
                </EmailShareButton>
              </ShareIcons>
            </InfoRow>
            <Spacer size={8} />
            <Divider />
            <Spacer size={64} />
            <VideoThumbWrapper onClick={() => setIsModalOpen(true)}>
              <PosterImage
                image={inrsVideo.thumbnail.asset.gatsbyImageData}
                alt={`${inrsVideo.title}`}
              />
              <PlayButtonWrap>
                <BreathingPlayButton animating={true} />
              </PlayButtonWrap>
            </VideoThumbWrapper>
            {/* <ModalWrapper>
              <ModalVideo
                channel='vimeo'
                isOpen={isModalOpen}
                videoId={getVimeoID(inrsVideo.vimeoLink)}
                onClose={() => setIsModalOpen(false)}
                ratio='16:9'
                vimeo={{
                  h: getVimeoUnlistedHash(inrsVideo.vimeoLink),
                  autoplay: true,
                  autopause: false,
                }}
              />
            </ModalWrapper> */}
            <VideoModal
              isModalOpen={isModalOpen}
              video={inrsVideo}
              onClose={() => setIsModalOpen(false)}
            />
            <Spacer size={64} />
            <P1>
              Irish production company Rubberduck is thrilled to announce its
              merger with production house Rocket Science, establishing
              Rubberduck as the exclusive brand moving forward. This union
              blends the agile, modern approach of Rubberduck with the
              rock-solid processes, experience, and heritage of the Rocket
              Science team, which boasts over 35 years in the Irish commercial
              production industry.
            </P1>
            <Spacer size={24} />
            <P1>
              The merger offers clients a creative, flexible, and agile
              production service while leveraging Rocket Science’s vast industry
              experience. Award-winning directors from the Rocket Science roster
              will join the Rubberduck family, creating a vibrant Irish home for
              them with the backing and reliability of seasoned professionals.
              The production studio will be led by the dynamic combination of
              producers, Eoghan Ryan, Helen Barry Duke and Jenni Barry Duke,
              alongside fresh and talented creatives, Ben Colwell and Conor
              Ryan.
            </P1>
            <Spacer size={48} />
            <ImageWrapper>
              <ImageStripes01 direction={'right'}>
                <Stripe10SVG />
              </ImageStripes01>
              <GatsbyImage
                image={groupPhoto.asset.gatsbyImageData}
                alt='New Rubberduck Team'
              />
              <ImageStripes02 direction={'left'}>
                <Stripe03SVG />
              </ImageStripes02>
              <Spacer size={4} />
              <ImageCaption>
                From left to right, Jenni Barry-Duke, Eoghan Ryan, <br />
                Ben Colwell, Conor Ryan, Helen Barry Duke
              </ImageCaption>
            </ImageWrapper>
            <Spacer size={48} />
            <P1>
              Rocket Science has produced some of the biggest and most widely
              recognised Irish commercials, with a deep understanding of the
              production process and the market it serves, it has long been a
              go-to production partner for brands and agencies alike.
            </P1>
            <Spacer size={24} />
            <P1>
              Following its inception in December 2019, the modern production
              model of Rubberduck quickly gained popularity, outgrowing its
              initial intentions to exclusively produce online content, to
              deliver a wide range of productions, from corporate communications
              and online campaigns to TV commercials for major Irish brands such
              as AIB, JustEat, Transport for Ireland and the Irish Defence
              Forces.
            </P1>
            <Spacer size={24} />
            <P1>
              As a result of this growth, Rubberduck has rapidly expanded its
              operations and staff, welcoming storyboard artists, producers, and
              editors to its ranks, bolstering it’s in house equipment, as well
              as setting up in-house post-production facilities and studio
              space.
            </P1>
            <Spacer size={24} />
            <P1>
              With a number of successful collaborations behind them, Rubberduck
              and Rocket Science have already worked on projects for major
              brands and agencies together. A standout job saw the united team
              produce a TV campaign for Liberty Insurance with ad agency ACNE
              and director Declan Lowney (Father Ted and Ted Lasso).
            </P1>
            <Spacer size={24} />
            <P1>
              Dylan Cotter, executive creative director of ACNE, said the newly
              formed Rubberduck were,
            </P1>
            <Spacer size={16} />
            <Blockquote>
              “the best bits of a big production house and a nimble content
              studio”
            </Blockquote>
            <Spacer size={48} />
            <P1>
              Eoghan Ryan, owner of Rocket Science comments on the merger.
            </P1>
            <Spacer size={24} />
            <QuoteLarge>
              “In the last 20 years the onset of digital technology has
              completely changed production. No longer do we shoot everything on
              film or get aerial shots from helicopters. Likewise, the global
              audience has changed with us - no longer do they wait a week for
              their fix of Dallas.
            </QuoteLarge>
            <Spacer size={24} />
            <QuoteLarge>
              Despite this, the core fundamentals remain the same. Production
              work is production work. You can have all the ideas in the world
              but if you don’t know how to go about getting it produced you are
              not a lot of use – the Rocket Science team bring this experience.
              In contrast, Rubberducks junior partners are both in their 20s.
              They are a new age hybrid of marketing guru’s, directors, computer
              geniuses – irritatingly talented. They bring a much-needed
              youthful creativity to the Rubberduck offering. There is very much
              a two-way learning stream between these young gurus and the elder
              lemons - and it’s this combination that sets our work apart. This
              move will let us deliver the same stellar creative, more
              efficiently than ever”,
            </QuoteLarge>
            <Spacer size={48} />
            <P1>
              Ben Colwell, co-founder of Rubberduck, comments on the exciting
              union,
            </P1>
            <Spacer size={24} />
            <QuoteLarge>
              “The last few years since starting Rubberduck have been a
              whirlwind of excitement, and collaborations with Rocket Science
              have certainly been a highlight. The wealth of production
              experience of their team is invaluable, and we synergise so well
              together, it’s like a secret weapon. I am buzzing that we’re
              making it official and look forward to seeing what creative work
              we can achieve with the directorial talent and experience Rocket
              Science brings paired with Rubberduck’s more modern, agile
              production approach.”
            </QuoteLarge>
            <Spacer size={48} />
          </ArticleContainer>
        </Container>
        <BottomCut dark />
      </Section>
      <Section dark style={{ overflow: 'clip' }}>
        <Container>
          <CTABlock data={ctaBlock} />
        </Container>
        <Spacer size={48} />
        <LogoMarquee clients={uniqueClients} />
      </Section>
    </>
  );
};

export default ItsNotRocketSciencePage;

export const INRSPageQuery = graphql`
  {
    sanityInrsPage {
      inrsVideo {
        ...SanityVideoFragment
      }
      groupPhoto {
        ...SanityImageFragment
      }
      ctaBlock {
        ...SanityCTABlockFragment
      }
    }
    allSanityVideo(
      filter: {
        rubberduckProduction: { eq: true }
        productTags: {
          elemMatch: { slug: { current: { eq: "tv-commercial" } } }
        }
      }
    ) {
      nodes {
        client {
          company
          svgLogo
        }
      }
    }
  }
`;
